import React, { useState, useEffect, useRef, useMemo, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Box, Button, CheckboxField, Form, RadioField, Stack, Typography } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { useMemberDetailContext } from '@icoach/members/memberDetail/MemberDetailContext';
import { refIsRequiredError } from '@util/utils';
import { ACHCard, CreditCard } from '@icoach/documents/components';

const getInitPaymentData = () => {
    return {
        creditCardBankName: '',
        creditCardType: 0,
        creditCardNo: '',
        cardExpiryDate: '',
        cardName: '',
        cardRelation: '',
        bankCode: '',
        bankName: '',
        bankAccount: '',
        bankAccountName: '',
        bankAccountID: '',
    };
};

const PaymentInformationBox = React.forwardRef((props, ref) => {
    const { className, options = [], sourceData } = props;
    const [paymentType, setPaymentType] = useState('');
    const contentRef = useRef(null);
    const paymentTypeRef = useRef(null);

    const handleChangePaymentType = (_e, value) => {
        setPaymentType(value);
    };

    const getPaymentComponents = useMemo(
        () => {
            return {
                1: <CreditCard ref={contentRef} defaultValue={sourceData} isShowCreditCardBankName isShowCardRelation required />,
                2: <ACHCard ref={contentRef} isSign={false} defaultValue={sourceData} />,
            };
        },
        // eslint-disable-next-line
        [sourceData],
    );

    useEffect(() => {
        setPaymentType(sourceData?.paymentType ?? 0);
    }, [sourceData]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const { memberPaymentID, title } = sourceData;
                let result = getInitPaymentData();

                result['memberPaymentID'] = memberPaymentID;
                result['title'] = title;
                result['paymentType'] = paymentType;

                if (contentRef.current && contentRef.current.getResult) {
                    Object.assign(result, contentRef.current.getResult());
                }

                return result;
            },
            isError: () => refIsRequiredError(paymentTypeRef, contentRef),
        }),
        [paymentType, sourceData],
    );

    if (isEmpty(sourceData)) return null;

    return (
        <React.Fragment>
            <Grid className={className} container spacing={2}>
                <Grid xs={12} item>
                    <Stack direction="row">
                        <RadioField key={paymentType} ref={paymentTypeRef} value={paymentType} onChange={handleChangePaymentType} row required>
                            {options.map(({ value, text }) => {
                                return <RadioField.Radio key={value} value={value} label={text} />;
                            })}
                        </RadioField>
                        <Typography className="text-gray payment-card-type-select" variant="body2" component="span">
                            (
                            <Box component={'span'} className="text-error">
                                **
                            </Box>
                            只能選擇一種付款方式)
                        </Typography>
                    </Stack>
                </Grid>
                <Grid xs={12} item>
                    {getPaymentComponents[paymentType] || null}
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

const MultiplePaymentDialogContent = React.forwardRef((props, ref) => {
    const { options, paymentInformationList } = props;
    const [isEditFuturePayment, setIsEditFuturePayment] = useState(false);
    const [futurePaymentInformation, setFuturePaymentInformation] = useState({});
    const currentPaymentInformationRef = useRef(null);
    const futurePaymentInformationRef = useRef(null);
    const currentPaymentInformation = paymentInformationList[0];

    const handleChangeEditFuturePayment = (e, value) => {
        setIsEditFuturePayment(value);
    };

    const handleOverwriteFuturePaymentInformation = () => {
        const { memberPaymentID, title } = paymentInformationList[1];
        let _currentPaymentInformation = {};

        if (currentPaymentInformationRef.current && currentPaymentInformationRef.current.getResult) {
            _currentPaymentInformation = {
                ...currentPaymentInformationRef.current.getResult(),
                memberPaymentID,
                title,
            };
        }

        setFuturePaymentInformation(_currentPaymentInformation);
    };

    useEffect(() => {
        if (!isEmpty(paymentInformationList) && paymentInformationList.length > 1) {
            setFuturePaymentInformation(paymentInformationList[1]);
        }
    }, [paymentInformationList]);

    useImperativeHandle(ref, () => ({
        isError: () => refIsRequiredError(currentPaymentInformationRef, futurePaymentInformationRef),
        getResult: () => {
            let list = [];

            if (currentPaymentInformationRef.current && currentPaymentInformationRef.current.getResult) {
                list = [...list, currentPaymentInformationRef.current.getResult()];
            }

            if (futurePaymentInformationRef.current && futurePaymentInformationRef.current.getResult) {
                list = [...list, futurePaymentInformationRef.current.getResult()];
            }

            return list;
        },
    }));

    return (
        <React.Fragment>
            <DialogContent>
                <Typography className={'payment-dialog-title'} variant={'h6'}>
                    當前會籍
                </Typography>
                <PaymentInformationBox className={'px-3'} ref={currentPaymentInformationRef} options={options} sourceData={currentPaymentInformation} />
                {!isEmpty(futurePaymentInformation) && (
                    <React.Fragment>
                        <Grid className={'mb-2 px-3'} spacing={2} container>
                            <Grid xs={12} item>
                                <CheckboxField key={isEditFuturePayment} defaultValue={[isEditFuturePayment]} onChange={handleChangeEditFuturePayment} row>
                                    <CheckboxField.Checkbox className={'text-error'} value={true} label={'該會員有續約，是否要進行編輯?'} />
                                </CheckboxField>
                            </Grid>
                        </Grid>
                        {isEditFuturePayment && (
                            <React.Fragment>
                                <Typography className={'payment-dialog-title'} variant={'h6'}>
                                    續約會籍
                                </Typography>
                                <Box className={'px-3'}>
                                    <Box className="tool-btn-group mb-1">
                                        <Button color={'grey'} onClick={handleOverwriteFuturePaymentInformation}>
                                            同當前會籍
                                        </Button>
                                    </Box>
                                    <PaymentInformationBox ref={futurePaymentInformationRef} options={options} sourceData={futurePaymentInformation} />
                                </Box>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </DialogContent>
        </React.Fragment>
    );
});

const MultiplePaymentDialog = (props) => {
    const { title, className, open, memberID, onClose: onCloseProps, sourceData: sourceDataProps } = props;
    const { refresh } = useMemberDetailContext();
    const { getMemberPaymentOptionsApi, getMemberProfilePaymentsMembershipApi, postMemberPaymentMembershipApi } = useMemberProfileApi();
    const [paymentInformationList, setPaymentInformationList] = useState([]);
    const [options, setOptions] = useState([]);
    const contentRef = useRef(null);

    const handleSubmit = () => {
        let isError = contentRef.current && contentRef.current.isError();
        if (!isError) {
            let params = contentRef.current && contentRef.current.getResult();
            doMemberPaymentApi(params, memberID);
        }
    };

    const doMemberPaymentApi = async (params, memberID) => {
        const resp = await postMemberPaymentMembershipApi(memberID, params);
        if (resp) {
            refresh.current && refresh.current(memberID);
            onCloseProps && onCloseProps();
        }
    };

    const getOptionsApi = async (memberID) => {
        const resp = await getMemberPaymentOptionsApi(memberID);
        if (resp) {
            setOptions(resp.paymentTypeOptions);
        }
    };

    const checkFutureContractPaymentInformationApi = async (memberID, sourceData) => {
        const { memberPaymentID } = sourceData;
        let resp = await getMemberProfilePaymentsMembershipApi(memberID, memberPaymentID);

        if (resp) {
            setPaymentInformationList(resp);
        }
    };

    useEffect(
        () => {
            if (open) {
                getOptionsApi(memberID);
                checkFutureContractPaymentInformationApi(memberID, sourceDataProps);
            }
        },
        // eslint-disable-next-line
        [open, sourceDataProps, memberID],
    );

    if (!open) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', 'payment-dialog', className)}
            PaperProps={{ className: 'wx-70rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onCloseProps}>{title}</GradientColorHeader>
            <MultiplePaymentDialogContent ref={contentRef} options={options} paymentInformationList={paymentInformationList} />
            <DialogActions>
                <Button onClick={onCloseProps} variant="outlined">
                    取消編輯
                </Button>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MultiplePaymentDialog;
