import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material/';
import { Button, SelectField, MenuItem, Form } from '@common/components/';
import UploadBox from '@icoach/components/UploadBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useEmployeeApi from '@apis/useEmployeeApi';
import useInBodyApi from '@apis/useInBodyApi';
import { refIsRequiredError } from '@util/utils';
import { UploadFeedbackBox } from '@icoach/components';
import { useLocalStorage } from '@util/hook/useStorage';
import { Grid } from '@mui/material';

const getInitFeedbackData = () => {
    return {
        isShow: false,
        isError: false,
        data: {},
    };
};

const UploadInbodyDataDialog = React.forwardRef((props, ref) => {
    const { open, onClose } = props;
    if (!open) return null;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { postUploadInbodyExcelApi, postUploadInbody370DM0536ExcelApi } = useInBodyApi();
    const [feedbackData, setFeedBackData] = useState(getInitFeedbackData());
    const [options, setOptions] = useState([]);
    const employeeIDRef = useRef(null);
    const uploadFileRef = useRef(null);
    const { value } = useLocalStorage('user');
    const is370DM0536 = /^(C071).*/g.test(value.userName);
    const fileExtensionFile = is370DM0536 ? ['xlsx'] : ['csv'];

    const getParams = () => {
        let result = {};

        if (employeeIDRef.current && employeeIDRef.current.getResult) {
            result = Object.assign(result, { employeeID: employeeIDRef.current.getResult() });
        }

        if (uploadFileRef.current && uploadFileRef.current.getResult) {
            result = Object.assign(result, { file: uploadFileRef.current.getResult() });
        }
        return result;
    };

    const initOptionsData = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    const doUploadInbodyExcelApi = async (params) => {
        let resp;
        if (is370DM0536) {
            resp = await postUploadInbody370DM0536ExcelApi(params);
        } else {
            resp = await postUploadInbodyExcelApi(params);
        }
        if (resp) {
            if (resp.isError) {
                const { isError, result } = resp;
                setFeedBackData({
                    isShow: true,
                    isError,
                    data: result,
                });
            } else {
                onClose();
            }
        }
    };

    const handleSubmit = () => {
        const isError = refIsRequiredError(uploadFileRef, employeeIDRef);
        if (!isError) {
            const formData = new FormData();
            const { employeeID, file } = getParams();
            formData.append('file', file.file, `${file.name}.${file.extension}`);
            formData.append('employeeID', employeeID);
            doUploadInbodyExcelApi(formData);
        }
    };

    useEffect(
        () => {
            if (open && options.length === 0) {
                initOptionsData();
            }

            if (!open) {
                setFeedBackData(getInitFeedbackData());
            }
        },
        // eslint-disable-next-line
        [open, options],
    );

    return (
        <Dialog className={'simple-content dialog'} PaperProps={{ className: 'wx-65rem', component: Form }} open={open} onSubmit={handleSubmit} fullWidth>
            <GradientColorHeader onClose={onClose}>批次上傳量身紀錄</GradientColorHeader>
            <DialogContent>
                {!feedbackData.isError ? (
                    <UploadBox ref={uploadFileRef} className={'import-ach-box'} fileExtension={fileExtensionFile} required />
                ) : (
                    <UploadFeedbackBox
                        isShow={feedbackData.isShow}
                        className={'import-ach-box'}
                        isError={feedbackData.isError}
                        {...feedbackData.data}
                        onConfirm={onClose}
                        onDownload={onClose}
                    />
                )}
                {!feedbackData.isShow && (
                    <Grid className={'mt-1'} spacing={3} container>
                        <Grid xs={6} item>
                            <SelectField label={'對象類型'} ref={employeeIDRef} displayEmpty fullWidth required>
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {/*{options.map(({ text, value, disabled }) => {*/}
                                {/*    return (*/}
                                {/*        <MenuItem key={value} value={value} disabled={disabled}>*/}
                                {/*            {text}*/}
                                {/*        </MenuItem>*/}
                                {/*    );*/}
                                {/*})}*/}
                            </SelectField>
                        </Grid>
                        <Grid xs={6} item>
                            <SelectField label={'擔當教練'} ref={employeeIDRef} displayEmpty fullWidth required>
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {options.map(({ text, value, disabled }) => {
                                    return (
                                        <MenuItem key={value} value={value} disabled={disabled}>
                                            {text}
                                        </MenuItem>
                                    );
                                })}
                            </SelectField>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            {!feedbackData.isShow && (
                <DialogActions>
                    <Button variant="contained" type="submit">
                        確認匯入
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
});

const InbodyUploadExcel = React.memo(() => {
    const [open, setOpen] = useState(false);
    const handleUploadClick = () => {
        setOpen(true);
    };

    const handleOnClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="contained" onClick={handleUploadClick}>
                批次上傳
            </Button>
            <UploadInbodyDataDialog open={open} onClose={handleOnClose} />
        </React.Fragment>
    );
});

export default InbodyUploadExcel;
